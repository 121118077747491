button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

@keyframes ping-pong {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
}

@keyframes pulse {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1.05);
  }
}
